<template>
  <div @mousewheel="closeActions">
    <h3 class="mb-4">{{ title }}</h3>
    <Table
      v-loading="loading"
      :data="tableData"
      :columns="columns"
      custom-class="ongoing-activity-table"
      show-index
      border
      rounded
      :expandable.sync="expandableRows"
      :cell-class="handleCellClass"
      @row-click="($event) => handleTableRowClick(tableData[$event])"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
      </template>
      <template #cell-paymentDueDate="{ rowData: { paymentDueDate, paymentTerm, paymentStatus, supplier }, rowIndex }">
        <Button
          v-if="paymentDueNetIsNotDefined(paymentTerm)"
          :disabled="updatingTerms"
          type="link"
          class="fw-normal p-0"
          @click.stop="openPaymentDueModal(supplier, paymentTerm)"
        >
          <p>{{ $t('payment.paymentTable.notDefined') }}</p>
        </Button>
        <el-popover
          v-else
          :value="activeDueDatePopover === rowIndex"
          placement="bottom"
          trigger="manual"
          popper-class="text-typography-primary p-2"
        >
          <Button
            slot="reference"
            type="link"
            class="p-0"
            :class="`text-${PAYMENT_STATUS_COLORS[paymentStatus]} ${PAYMENT_STATUS_COLORS[paymentStatus]}`"
            @click.stop="handleDueDateClick(rowIndex)"
          >
            <div class="d-flex" style="gap: 2px">
              <p class="warning-button">{{ formatDate(paymentDueDate) }}</p>
            </div>
          </Button>
          <div class="pb-1 ps-1">
            <div v-if="paymentStatus !== PAYMENT_STATUS.UNPAID" class="d-flex gap-1">
              <AlertIcon :class="`text-${PAYMENT_STATUS_COLORS[paymentStatus]}`" />
              <div class="pt-1">
                <p class="fw-bold">
                  {{
                    $tc(
                      `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm.eom ? 'Eom' : ''}`,
                      paymentTerm.paymentDueNet,
                      {
                        count: paymentTerm.paymentDueNet,
                      }
                    )
                  }}
                </p>
                <small>
                  <i18n :path="`payment.paymentTable.overduePopover.${paymentStatus}`">
                    <template #date>
                      <small v-if="paymentStatus === PAYMENT_STATUS.OVERDUE" class="fw-bold">
                        {{ getPastDaysNumber(paymentDueDate) }}
                      </small>
                      <small v-if="paymentStatus === PAYMENT_STATUS.APPROACHING_DUE" class="fw-bold">
                        {{ getApproachingDaysNumber(paymentDueDate) }}
                      </small>
                    </template>
                  </i18n>
                </small>
              </div>
            </div>
            <hr v-if="paymentStatus !== PAYMENT_STATUS.UNPAID" class="my-2" />
            <div class="d-flex gap-1">
              <TermsAndAgreementsIcon />
              <div class="pt-1">
                <p class="fw-bold">{{ $t('payment.paymentTable.paymentTerms') }}</p>
                <small>
                  {{
                    $tc(
                      `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm.eom ? 'Eom' : ''}`,
                      paymentTerm.paymentDueNet,
                      {
                        count: paymentTerm.paymentDueNet,
                      }
                    )
                  }}
                </small>
              </div>
            </div>
          </div>
        </el-popover>
      </template>
      <template #cell-paymentTerm="{ rowData: { paymentTerm, supplier } }">
        <div class="d-flex align-items-center gap-1">
          <Button
            v-if="!paymentTerm || (paymentTerm && !paymentTerm.paymentMethod)"
            :disabled="updatingTerms"
            type="link"
            class="fw-normal p-0 add-btn"
            @click.stop="openPaymentMethodModal(supplier, paymentTerm)"
          >
            <NewIcon />
          </Button>
          <template v-else>
            <div class="position-relative" style="margin-top: -2px">
              <el-tooltip :content="getPaymentTermTooltipContent(paymentTerm)" placement="right" :open-delay="500">
                <span>
                  <BankTransferIcon
                    v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER"
                    width="16"
                    height="16"
                  />
                  <ChequeIcon v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.CHEQUE" width="16" height="16" />
                  <CreditCardIcon
                    v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.CREDIT_CARD"
                    width="16"
                    height="16"
                  />
                  <RecurringIcon
                    v-if="paymentTerm.directDebit"
                    width="14"
                    height="14"
                    class="position-absolute payment-badge-position"
                  />
                </span>
              </el-tooltip>
            </div>
          </template>
          <Button
            v-if="paymentDueNetIsNotDefined(paymentTerm)"
            :disabled="updatingTerms"
            type="link"
            class="fw-normal p-0 m-0"
            @click.stop="openPaymentDueModal(supplier, paymentTerm)"
          >
            <p>{{ $t('operationManagement.ongoingOperations.table.notDefined') }}</p>
          </Button>
          <div v-else class="w-100">
            <TruncatedText>
              {{
                $tc(
                  `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm.eom ? 'Eom' : ''}`,
                  paymentTerm.paymentDueNet,
                  {
                    count: paymentTerm.paymentDueNet,
                  }
                )
              }}</TruncatedText
            >
          </div>
        </div>
      </template>
      <template #cell-billingCount="{ rowData: { billedAmounts, billingsLeftToBePaid, billingAggregation }, rowData }">
        <p v-if="billingAggregation">{{ billingAggregation.count }}</p>
        <Button
          v-else-if="billedAmounts.length"
          type="link"
          class="text-typography-primary p-0"
          @click.stop="paymentInvoicesModalPayment = rowData"
        >
          <template v-if="billingsLeftToBePaid.length !== billedAmounts.length">
            <p>
              {{
                $t('operationManagement.ongoingOperations.table.billingCountField', {
                  total: billedAmounts.length,
                  leftToPay: billingsLeftToBePaid.length,
                })
              }}
            </p>
          </template>
          <template v-else>
            <p>
              {{ billedAmounts.length }}
            </p>
          </template>
        </Button>
        <template v-else>
          <p>
            <TruncatedText> {{ $t('operationManagement.ongoingOperations.table.notYetReceived') }}</TruncatedText>
          </p>
        </template>
      </template>
      <template #cell-invoiceDate="{ rowData: { reconciliationPeriod, isDaily } }">
        {{ formatDateShort(reconciliationPeriod, isDaily) }}
      </template>
      <template
        #cell-totalPayment="{
          rowData: { billedAmounts, billingIds, supplier, totalBilledAmount, totalPaidAmount, unbilledOrders },
        }"
      >
        <TotalPaymentField
          :billed-amounts="billedAmounts"
          :billing-ids="billingIds"
          :supplier="supplier"
          :customer="currentTenant"
          :total-billed-amount="totalBilledAmount"
          :total-paid-amount="totalPaidAmount"
          :unbilled-orders="unbilledOrders"
          truncated
        />
      </template>
      <template
        #cell-payablesBalance="{ rowData: { payablesBalance, overdue, paymentDueDate, hasInitialBalance }, rowIndex }"
      >
        <p v-if="!hasInitialBalance">{{ $t('payment.paymentTable.notDefined') }}</p>
        <p v-else-if="!overdue.length">{{ formatToCurrency(payablesBalance) ?? '-' }}</p>
        <el-popover
          v-else
          :value="activeBalanceDuePopover === rowIndex"
          placement="bottom"
          trigger="manual"
          popper-class="p-2 text-typography-primary"
        >
          <Button
            slot="reference"
            type="link"
            class="text-warning warning p-0"
            @click.stop="handleBalanceDueClick(rowIndex)"
          >
            <div class="d-flex" style="gap: 2px">
              <p class="warning-button">{{ formatToCurrency(payablesBalance) ?? '-' }}</p>
            </div>
          </Button>
          <div class="ps-1 pb-1 lh-1">
            <div class="d-flex gap-1">
              <AlertIcon class="text-warning" />
              <div class="pt-1">
                <p class="fw-bold">{{ $tc('payment.paymentTable.balanceDuePopover.title', overdue.length) }}</p>
                <small>
                  <span>
                    {{
                      $tc('payment.paymentTable.balanceDuePopover.subtitle', overdue.length, { count: overdue.length })
                    }}
                  </span>
                  <span class="fw-bold">{{ formatDate(paymentDueDate) }}</span>
                </small>
              </div>
            </div>
            <div class="py-1">
              <hr class="my-2" />
            </div>
            <ul class="m-0 p-0">
              <li v-for="overdueItem in overdue" :key="overdueItem.id" class="row g-0 justify-content-between mb-1">
                <small class="col-7">{{ formatDate(overdueItem.paymentDueDate) }}</small>
                <small class="col-5">{{ formatToCurrency(overdueItem.totalUnpaidAmount) ?? '-' }}</small>
              </li>
            </ul>
            <div class="py-1">
              <hr class="my-2" />
            </div>
            <div class="d-flex justify-content-between">
              <small class="fw-bold"> {{ $t('commons.total') }}</small>
              <small class="fw-bold"> {{ getTotalOverdue(overdue) ?? '-' }} </small>
            </div>
          </div>
        </el-popover>
      </template>
      <template #cell-processStatus="{ rowData: { status, statusOverride, supplier, id }, rowIndex }">
        <ReconciliationStatus
          :is-admin="isAdmin"
          :reconciliation-status="status"
          :reconciliation-id="id"
          :status-override="statusOverride"
          :business-id="currentTenant.id"
          :supplier="supplier"
          :show="activeReconciliationStatus === rowIndex"
          @open="activeReconciliationStatus = rowIndex"
          @close="activeReconciliationStatus = -1"
        />
      </template>
      <template #cell-lastReport="{ rowData: { lastReport: reportStatus, reconciliationDocIds } }">
        <div v-if="reportStatus" class="d-flex align-items-center gap-2">
          <div class="position-relative">
            <ADRIcon v-if="reportStatus.type === RECONCILIATION_DOCUMENT_TYPES.AGED_DEBTORS_REPORT" />
            <RSIcon v-if="reportStatus.type === RECONCILIATION_DOCUMENT_TYPES.RECONCILIATION_STATEMENT" />
            <UnknownDocumentIcon v-if="reportStatus.type === RECONCILIATION_DOCUMENT_TYPES.UNKNOWN" />
          </div>
          <Button
            v-if="reportStatus.reconciliationDocument"
            type="link"
            class="text-typography-primary p-0"
            @click.stop="
              handleOpenDocument({
                documentId: reportStatus.reconciliationDocument.id,
                documentsIds: reconciliationDocIds,
              })
            "
          >
            {{ formatDate(reportStatus.reconciliationDocument.issueDate) }}
          </Button>
        </div>
      </template>
      <template v-if="!tasksLoading" #cell-openTasks="{ rowData: { tasks, supplier } }">
        <div class="cell_open-tasks">
          <TasksDetail
            v-if="hasReconciliationManage"
            :tasks="tasks"
            :supplier-name="supplier.name"
            :customer-name="currentTenant.name"
            @update-task="handleTaskUpdate"
          >
            <template v-if="tasks.length" #button>
              {{ $tc('billing.billingManagement.billingTable.tasksNumber', tasks.length) }}
            </template>
          </TasksDetail>
        </div>
      </template>
      <template
        v-if="!loading"
        #cell-chat="{ rowData: { supplier, id, reconciliationPeriod, reconciliationClosed, isDaily } }"
      >
        <Button
          v-if="supplier && channelUnreadMessagesCount[id] >= 0"
          class="position-relative p-1"
          type="icon"
          @click.stop="
            handleChatOpen({ supplier, reconciliationId: id, reconciliationPeriod, reconciliationClosed, isDaily })
          "
        >
          <ChatIcon />
          <span
            v-if="channelUnreadMessagesCount[id] > 0"
            class="badge rounded-pill top-0 bg-danger position-absolute border border-2 border-white"
            :class="{ ['start-0']: $direction === 'rtl', ['end-0']: $direction === 'ltr' }"
          >
            {{ channelUnreadMessagesCount[id] > 99 ? '+99' : channelUnreadMessagesCount[id] }}
          </span>
        </Button>
      </template>
      <template
        #cell-actions="{
          rowData: {
            status,
            supplier,
            totalBilledAmount,
            totalPaidAmount,
            reconciliationClosed,
            balanceAlignment,
            unbilledOrders,
            hasInitialBalance,
          },
          rowIndex,
        }"
      >
        <el-dropdown
          v-if="supplier"
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom"
          @command="(command) => handleAction(command, rowIndex)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="icon"
            class="actions-btn"
            :class="{ active: activeRowActionsIndex === rowIndex }"
            @click.stop="handleAction($event, rowIndex)"
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item
              :disabled="!status || status.status === RECONCILIATION_STATUSES.APPROVED"
              :command="ACTIONS.OPEN_ISSUES_MODAL"
            >
              <div class="d-flex align-items-center gap-2">
                <AccountingIcon />
                {{ $t('billing.billingManagement.billingTable.actions.openIssuesModal') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item :command="ACTIONS.OPEN_CREDIT_MATCH_MODAL">
              <div class="d-flex align-items-center gap-2">
                <DocumentMatchingIcon />
                {{ $t('billing.billingManagement.billingTable.actions.openCreditMatchModalData') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided :command="ACTIONS.OPEN_EVENT_MAP">
              <div class="d-flex align-items-center gap-2">
                <OngoingActivityIcon /> {{ $t('billing.billingManagement.billingTable.actions.openEventMap') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item :command="ACTIONS.OPEN_RECONCILIATION_REPORT">
              <div class="d-flex align-items-center gap-2">
                <ReconciliationIcon /> {{ $t('billing.billingManagement.billingTable.actions.transactions') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item :command="ACTIONS.OPEN_AGED_DEBTORS_REPORT">
              <div class="d-flex align-items-center gap-2">
                <DebtIcon /> {{ $t('billing.billingManagement.billingTable.actions.agedDebtorsReport') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided :disabled="!isAdmin" :command="ACTIONS.OPEN_RECONCILIATION_SETTINGS">
              <div class="d-flex align-items-center gap-2">
                <SettingsIcon /> {{ $t('billing.billingManagement.billingTable.actions.singleReconciliationSettings') }}
                <Tag v-if="!supplierTemplateDefined(supplier.id)">
                  {{ $t('billing.billingManagement.billingTable.actions.default') }}
                </Tag>
              </div>
            </el-dropdown-item>
            <el-dropdown-item :disabled="!isAdmin || !!hasInitialBalance" :command="ACTIONS.BALANCE_UPDATE">
              <div
                class="d-flex align-items-center gap-2"
                :class="`${!isAdmin || !!hasInitialBalance ? 'text-disabled' : 'text-typography-primary'}`"
              >
                <SettingsIcon />
                <p>{{ $t('payment.paymentTable.singleRowActions.balanceUpdate') }}</p>
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              divided
              :disabled="!isAdmin || !balanceAlignment.required || balanceAlignment.validated"
              :command="ACTIONS.VALIDATE_BALANCE_ALIGNMENT"
            >
              <div class="d-flex align-items-center gap-2">
                <CheckCircleIcon />
                {{ $t('billing.billingManagement.billingTable.actions.validateBalanceAlignment') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="isAdmin && !reconciliationClosed"
              :disabled="totalPaidAmount !== totalBilledAmount"
              :command="ACTIONS.CLOSE_RECONCILIATION"
            >
              <div
                class="d-flex align-items-center gap-2 text-typography-primary"
                :class="`${
                  totalPaidAmount !== totalBilledAmount || reconciliationClosed
                    ? 'text-disabled'
                    : 'text-typography-primary'
                }`"
              >
                <ChequeredFlagIcon />
                <p>{{ $t('payment.paymentTable.singleRowActions.closeReconciliation') }}</p>
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="isAdmin && reconciliationClosed"
              :disabled="totalPaidAmount === totalBilledAmount && unbilledOrders && unbilledOrders.length === 0"
              :command="ACTIONS.OPEN_RECONCILIATION"
            >
              <div
                class="d-flex align-items-center gap-2 text-typography-primary"
                :class="`${
                  totalPaidAmount === totalBilledAmount && unbilledOrders && unbilledOrders.length === 0
                    ? 'text-disabled'
                    : 'text-typography-primary'
                }`"
              >
                <ChequeredFlagReturnIcon />
                <p>{{ $t('operationManagement.ongoingOperations.table.rowActions.openReconciliation') }}</p>
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided :command="ACTIONS.OPEN_CHAT">
              <div class="d-flex align-items-center gap-2">
                <ChatIcon />
                {{ $t('billing.billingManagement.billingTable.actions.openChat') }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template
        #expandable-content="{ rowData: { billingIds, orderIds, paymentIds, missingEvents, deliveryIds, status } }"
      >
        <ActivityInfo
          :is-admin="isAdmin"
          :reconciliations-status="status"
          :missing-loading="missingEventsLoading"
          :billing-ids="billingIds"
          :order-ids="orderIds"
          :payment-ids="paymentIds"
          :delivery-ids="deliveryIds"
          :missing-events="missingEvents"
          @open-issues="
            handleInnerTableActions(ACTIONS.OPEN_ISSUES_MODAL, {
              billingId: $event.id,
              supplier: { id: $event.supplierId },
              date: $event.date,
            })
          "
          @open-credit-match="
            handleInnerTableActions(ACTIONS.OPEN_CREDIT_MATCH_MODAL, {
              billingId: $event.billingId,
              supplier: { id: $event.supplierId },
              date: $event.date,
            })
          "
        />
      </template>
      <template #filter-invoiceDate>
        <MonthPicker v-model="invoiceDateFilter" clearable />
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="pageLimit"
        :page-count="Math.ceil(totalImbalances / pageLimit)"
        :total="totalImbalances"
      />
    </div>
    <DocumentModal
      v-if="displayedDocument.documentId"
      visible
      :document-id="displayedDocument.documentId"
      @close="displayedDocument = {}"
    >
      <template v-if="displayedDocument.documentsIds && displayedDocument.documentsIds.length" #header>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :page-size="1"
          :total="displayedDocument.documentsIds.length"
          :current-page="displayedDocument.index + 1"
          :pager-count="5"
          @update:currentPage="handleDocumentModalPagination"
        />
      </template>
    </DocumentModal>
    <PaymentDueModal
      v-if="supplierPaymentDueModal && supplierPaymentDueModal.id"
      :loading="paymentTermLoading || paymentTermChangedForceLoading"
      :supplier="supplierPaymentDueModal.name"
      :country-code="currentTenant.countryCode"
      @close="supplierPaymentDueModal = false"
      @submit="handlePaymentDueNetChange"
    />
    <PaymentMethodModal
      v-if="isPaymentMethodModalOpen"
      :loading="paymentTermLoading"
      :bank-accounts="bankAccounts"
      @close="isPaymentMethodModalOpen = false"
      @update="handlePaymentMethodChange"
    />
    <PaymentInvoicesModal
      v-if="paymentInvoicesModalPayment"
      :payment="paymentInvoicesModalPayment"
      @close="paymentInvoicesModalPayment = null"
    />
    <BalancePaymentModal
      v-if="balancePaymentModalSupplier"
      :supplier="balancePaymentModalSupplier"
      :business-id="currentTenant.id"
      @close="balancePaymentModalSupplier = null"
      @balance-payment-create="refetchData"
    />
    <IssuesModal
      v-if="issueModalData"
      :month="issueModalData && issueModalData.month"
      :supplier-id="issueModalData && issueModalData.supplierId"
      :business-id="currentTenant.id"
      :billing-id="issueModalData && issueModalData.billingId"
      @close="issueModalData = null"
    />
    <CreditMatchModal
      v-if="creditMatchModalData"
      :selected-billing-id="creditMatchModalData && creditMatchModalData.billingId"
      :default-month="creditMatchModalData && creditMatchModalData.month"
      :supplier="creditMatchModalData && creditMatchModalData.supplier"
      :business-id="currentTenant.id"
      @close="creditMatchModalData = null"
    />
    <ReconciliationModal
      v-if="pickedReconciliationId"
      ref="reconciliationModal"
      :reconciliation-id="pickedReconciliationId"
      @reconciliation-closed="refetchReconciliations"
      @close="handleReconciliationModalClose"
      @open-chat="handleChatOpen"
      @update="refetchData"
    />
    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="suppliers.find((supplier) => supplier.id === reconciliationTemplateParams.supplierId)"
      :business="currentTenant"
      :reconciliation-template="reconciliationTemplateParams.reconciliationTemplate"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleReconciliationTemplateSave(params)"
    />
    <BalanceAlignmentModal
      v-if="balanceAlignmentReconciliation"
      :reconciliation="balanceAlignmentReconciliation"
      @close="balanceAlignmentReconciliation = null"
      @validate="validateBalanceAlignment"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { reject, isNil, omit, uniq } from 'ramda';
import { computed, onBeforeUnmount, ref, watch, getCurrentInstance } from 'vue';

import {
  AlertIcon,
  TermsAndAgreementsIcon,
  BankTransferIcon,
  ChequeIcon,
  CreditCardIcon,
  RecurringIcon,
  NewIcon,
  ChatIcon,
  AccountingIcon,
  DocumentMatchingIcon,
  OngoingActivityIcon,
  ReconciliationIcon,
  DebtIcon,
  CheckCircleIcon,
  KebabIcon,
  SettingsIcon,
  ADRIcon,
  RSIcon,
  UnknownDocumentIcon,
  ChequeredFlagIcon,
  ChequeredFlagReturnIcon,
} from '@/assets/icons';

const icons = {
  AlertIcon,
  TermsAndAgreementsIcon,
  BankTransferIcon,
  ChequeIcon,
  CreditCardIcon,
  RecurringIcon,
  NewIcon,
  ChatIcon,
  AccountingIcon,
  DocumentMatchingIcon,
  OngoingActivityIcon,
  ReconciliationIcon,
  DebtIcon,
  CheckCircleIcon,
  KebabIcon,
  SettingsIcon,
  ADRIcon,
  RSIcon,
  UnknownDocumentIcon,
  ChequeredFlagIcon,
  ChequeredFlagReturnIcon,
};

import { Table, Button, Tag, MonthPicker, TruncatedText } from '@/modules/core';
import { PaymentDueModal, PaymentMethodModal, useCreateTerm, useUpdateTerm } from '@/modules/term';
import {
  usePayables,
  useBalancePayments,
  PaymentInvoicesModal,
  BalancePaymentModal,
  ActivityInfo,
  useBankAccounts,
} from '@/modules/payment';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { PAYMENT_STATUS, PAYMENT_STATUS_COLORS, PAYMENT_METHOD_TYPE } from '@/modules/payment/types';
import { useReconciliationDocuments, useUncertainBillings } from '@/modules/billing';
import {
  RECONCILIATION_STATUSES,
  useReconciliationTemplates,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
  useReconciliations,
  useUpdateReconciliation,
  useMissingEvents,
  useChannel,
} from '@/modules/reconciliation';
import { useSuppliersNew } from '@/modules/suppliers';
import { useTenancy, useUser } from '@/modules/auth';
import { useGlobalPermissions } from '@/modules/permissions';
import { DocumentModal } from '@/modules/documentModal';
import { useChatModal } from '@/modules/chatModal';
import { useTasks, usePatchTask, useActivateTask } from '@/modules/tasks';
import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import CreditMatchModal from '../creditMatchModal';
import { formatDate, formatBilledDate } from './tools/formatters';
import {
  mapImbalanceWithBalanceAndDueDateTerm,
  getLastReport,
  filterMissingEventsByDateAndSupplier,
} from './tools/dataTransformation';
import { useTerms, useCloseReconciliation } from './composition';

const TABLE_HEADERS = {
  PAYMENT_DUE_DATE: 'paymentDueDate',
  PAYMENT_TERM: 'paymentTerm',
  SUPPLIER_NAME: 'supplierName',
  UNBILLED_COUNT: 'unbilledCount',
  BILLING_COUNT: 'billingCount',
  INVOICE_DATE: 'invoiceDate',
  TOTAL_PAYMENT: 'totalPayment',
  PAYABLES_BALANCE: 'payablesBalance',
  PROCESS_STATUS: 'processStatus',
  LAST_REPORT: 'lastReport',
  OPEN_TASKS: 'openTasks',
};

const ACTIONS = {
  BALANCE_UPDATE: 'balanceUpdate',
  OPEN_ISSUES_MODAL: 'issuesModal',
  OPEN_CREDIT_MATCH_MODAL: 'creditMatchModal',
  OPEN_EVENT_MAP: 'openEventMap',
  OPEN_RECONCILIATION_REPORT: 'openReconciliationStatementReport',
  OPEN_RECONCILIATION_SETTINGS: 'openReconciliationSettings',
  OPEN_AGED_DEBTORS_REPORT: 'openAgedDebtorsReport',
  OPEN_CHAT: 'openChat',
  CLOSE_RECONCILIATION: 'closeReconciliation',
  OPEN_RECONCILIATION: 'openReconciliation',
  VALIDATE_BALANCE_ALIGNMENT: 'validateBalanceAlignment',
};

const RECONCILIATION_DOCUMENT_TYPES = {
  RECONCILIATION_STATEMENT: 'reconciliationStatement',
  AGED_DEBTORS_REPORT: 'agedDebtorsReport',
  UNKNOWN: 'unknown',
};

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export default {
  components: {
    ...icons,
    Tag,
    Table,
    Button,
    MonthPicker,
    PaymentDueModal,
    PaymentMethodModal,
    PaymentInvoicesModal,
    BalancePaymentModal,
    ActivityInfo,
    CreditMatchModal,
    TruncatedText,
    TasksDetail: () => import('@/modules/tasks/components/TasksDetail'),
    DocumentModal,
    IssuesModal: () => import('@/modules/modals/issuesModal/IssuesModal'),
    ReconciliationStatus: () => import('@/modules/reconciliation/reconciliationStatus/ReconciliationStatus'),
    ReconciliationSettingsModal: () =>
      import('@/modules/reconciliation/reconciliationSettingsModal/ReconciliationSettingsModal'),
    ReconciliationModal: () => import('@/modules/reconciliation/reconciliationModal/ReconciliationModal'),
    BalanceAlignmentModal: () => import('@/modules/reconciliation/components/BalanceAlignmentModal'),
    TotalPaymentField: () => import('@/modules/reconciliation/components/TotalPaymentField'),
  },
  props: {
    supplierId: { type: String, default: null },
    closedReconciliations: { type: Boolean, required: true },
    sort: { type: Object, required: true },
    title: { type: String, required: true },
  },
  setup(props) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const expandableRows = ref({});
    const invoiceDateFilter = ref('');
    const updatingTerms = ref(false);
    const supplierFilter = computed(() => props.supplierId);

    const { currentTenant } = useTenancy();
    const { formatToCurrency } = useCurrency();
    const { hasReconciliationManage } = useGlobalPermissions();
    const { isAdmin } = useUser();
    const { suppliers, loading: suppliersLoading, refetch: refetchSuppliers } = useSuppliersNew();

    const { reconciliationTemplates, refetch: reconciliationTemplatesRefetch } = useReconciliationTemplates(
      computed(() => ({
        businessId: currentTenant.value.id,
      }))
    );

    const {
      handleReconciliationTasks,
      supplierCreationTasks,
      uncertainBillingTasks,
      balanceAlignmentTasks,
      loading: tasksLoading,
      refetch: tasksRefetch,
    } = useTasks(computed(() => ({ businessId: currentTenant.value?.id, completed: false })));

    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();

    const options = computed(() => ({ enabled: !!currentTenant.value.id }));

    const {
      terms,
      loading: loadingTerms,
      refetch: refetchTerms,
    } = useTerms(() => ({
      businessId: currentTenant.value.id,
    }));

    const {
      payables,
      loading: payablesLoading,
      refetch: refetchPayables,
    } = usePayables(
      computed(() =>
        reject(isNil)({
          businessId: currentTenant.value.id,
          supplierId: supplierFilter.value,
        })
      ),
      options
    );

    const {
      balancePayments,
      loading: balancePaymentsLoading,
      refetch: refetchBalancePayments,
    } = useBalancePayments(
      computed(() => ({
        businessId: currentTenant.value.id,
      })),
      options
    );

    const pageLimit = 25;
    const currentPage = ref(1);

    const variablesOfReconciliations = computed(() => {
      const fromPeriodDate = invoiceDateFilter.value ? DateTime.fromFormat(invoiceDateFilter.value, 'yyyy-MM') : null;
      const toPeriodDate = fromPeriodDate?.endOf('month');
      return {
        businessId: currentTenant.value.id,
        supplierId: supplierFilter.value,
        fromPeriodDate: fromPeriodDate?.toISODate(),
        toPeriodDate: toPeriodDate?.toISODate(),
        closed: props.closedReconciliations,
        pagination: {
          limit: pageLimit,
          offset: (currentPage.value - 1) * pageLimit,
        },
        sort: props.sort,
      };
    });

    const {
      reconciliations,
      totalCount: totalImbalances,
      loading: reconciliationsLoading,
      refetch: refetchReconciliations,
    } = useReconciliations(variablesOfReconciliations, options);

    const { mutate: closeReconciliation } = useCloseReconciliation(variablesOfReconciliations);

    const {
      missingEvents,
      loading: missingEventsLoading,
      refetch: refetchMissingEvents,
    } = useMissingEvents(() => ({
      businessId: currentTenant.value.id,
    }));

    const { documents: rehandleParentDocuments, loading: rehandleParentDocumentsLoading } = useDocumentsByIds(
      computed(() => ({
        businessId: currentTenant.value.id,
        ids: uniq(
          missingEvents.value
            .filter(({ parentDocumentId }) => parentDocumentId)
            .map(({ parentDocumentId }) => parentDocumentId)
        ),
      }))
    );

    const { mutate: updateReconciliation } = useUpdateReconciliation();

    const forceLoading = ref(false);
    const loading = computed(
      () =>
        payablesLoading.value ||
        balancePaymentsLoading.value ||
        reconciliationsLoading.value ||
        suppliersLoading.value ||
        missingEventsLoading.value ||
        rehandleParentDocumentsLoading.value ||
        loadingTerms.value ||
        forceLoading.value
    );

    const refetchData = async () => {
      forceLoading.value = true;
      await Promise.all([
        refetchMissingEvents(),
        refetchSuppliers(),
        refetchBalancePayments(),
        refetchReconciliations(),
        refetchPayables(),
        refetchTerms(),
      ]);
      forceLoading.value = false;
      updatingTerms.value = false;
    };

    const paymentImbalances = computed(() =>
      !loading.value
        ? mapImbalanceWithBalanceAndDueDateTerm(
            reconciliations.value,
            payables.value,
            balancePayments.value,
            suppliers.value,
            terms.value
          )
        : []
    );

    const dateRange = computed(() => {
      if (!paymentImbalances.value.length) return null;

      const sortedByPeriod = [...paymentImbalances.value].sort(
        (a, b) => new Date(a.periodStart) - new Date(b.periodStart)
      );
      const minDate = sortedByPeriod[0].periodStart;
      const maxDate = sortedByPeriod[sortedByPeriod.length - 1].periodEnd;

      const toDate = DateTime.fromISO(maxDate);
      const fromDate =
        !supplierFilter.value && DateTime.fromISO(minDate) < toDate.minus({ years: 1 })
          ? toDate.minus({ years: 1 })
          : DateTime.fromISO(minDate);
      return {
        fromDate: fromDate.toISODate(),
        toDate: toDate.toISODate(),
      };
    });

    const { reconciliationDocuments } = useReconciliationDocuments(
      computed(() =>
        reject(isNil)({
          fromDate: dateRange.value?.fromDate,
          supplierId: supplierFilter.value,
          businessId: currentTenant.value.id,
        })
      )
    );

    const { createTerm, loading: createTermLoading, onError } = useCreateTerm();
    const supplierPaymentDueModal = ref(null);
    const isPaymentMethodModalOpen = ref(false);
    const chosenSupplier = ref(null);
    const chosenSupplierPaymentTerm = ref(null);

    const { bankAccounts } = useBankAccounts(computed(() => chosenSupplier.value?.id));

    const { uncertainBillings } = useUncertainBillings(computed(() => ({ businessId: currentTenant.value.id })));

    const termUpdated = () => {
      $message.success($i18n.t('commons.messages.action.success'));
      supplierPaymentDueModal.value = null;
      isPaymentMethodModalOpen.value = false;
      chosenSupplier.value = null;
      chosenSupplierPaymentTerm.value = null;
      refetchData();
    };

    onError(() => {
      $message.error($i18n.t('commons.messages.action.error'));
    });

    const { updateTerm, loading: updateTermLoading, onError: updateTermOnError } = useUpdateTerm();

    updateTermOnError(() => {
      $message.error($i18n.t('commons.messages.action.error'));
    });

    const reconciliationIds = computed(() => uniq(paymentImbalances.value.map(({ id }) => id)));

    const { closeChatModal, openChatModal, isChatOpen } = useChatModal();
    const { channelUnreadMessagesCount } = useChannel(reconciliationIds);

    watch(supplierFilter, () => closeChatModal(), { immediate: true });

    const { patchTask } = usePatchTask();
    const { activeTask } = useActivateTask();

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    const paymentTermChangedForceLoading = ref(false);

    return {
      hasReconciliationManage,
      tasksLoading,
      tasksRefetch,
      handleReconciliationTasks,
      balanceAlignmentTasks,
      supplierCreationTasks,
      uncertainBillingTasks,
      closeReconciliation,
      refetchReconciliations,
      reconciliationTemplateParams: ref(null),
      suppliers,
      reconciliationTemplatesRefetch,
      reconciliationTemplates,
      createReconciliationTemplate,
      updateReconciliationTemplate,
      updateReconciliation,
      PAYMENT_STATUS,
      PAYMENT_STATUS_COLORS,
      PAYMENT_METHOD_TYPE,
      RECONCILIATION_STATUSES,
      RECONCILIATION_DOCUMENT_TYPES,
      ACTIONS,
      currentTenant,
      isAdmin,
      paymentImbalances,
      totalImbalances,
      currentPage,
      pageLimit,
      payables,
      balancePayments,
      loading,
      reconciliationDocsBySupplier: computed(() =>
        reconciliationDocuments.value.reduce((suppliersMap, document) => {
          if (!suppliersMap[document.supplierId]) suppliersMap[document.supplierId] = [document];
          else suppliersMap[document.supplierId].push(document);
          return suppliersMap;
        }, {})
      ),
      reconciliations,
      createTerm,
      updateTerm,
      supplierPaymentDueModal,
      isPaymentMethodModalOpen,
      chosenSupplier,
      bankAccounts,
      uncertainBillings,
      chosenSupplierPaymentTerm,
      activeDueDatePopover: ref(-1),
      activeBalanceDuePopover: ref(-1),
      activeReconciliationStatus: ref(-1),
      activeRowActionsIndex: ref(-1),
      paymentInvoicesModalPayment: ref(null),
      closeChatModal,
      openChatModal,
      isChatOpen,
      channelUnreadMessagesCount,
      balancePaymentModalSupplier: ref(null),
      issueModalData: ref(null),
      refetchData,
      creditMatchModalData: ref(null),
      missingEventsLoading,
      missingEvents,
      rehandleParentDocuments,
      displayedDocument: ref({}),
      pickedReconciliationId: ref(null),
      expandableRows,
      paymentTermChangedForceLoading,
      paymentTermLoading: computed(() => createTermLoading.value || updateTermLoading.value),
      termUpdated,
      invoiceDateFilter,
      updatingTerms,
      balanceAlignmentReconciliation: ref(null),
      patchTask,
      activeTask,
      formatToCurrency,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.paymentDueDate'),
          key: TABLE_HEADERS.PAYMENT_DUE_DATE,
          width: '110px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.paymentTerms'),
          key: TABLE_HEADERS.PAYMENT_TERM,
          width: '120px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.supplierName'),
          key: TABLE_HEADERS.SUPPLIER_NAME,
          width: '150px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.unbilledCount'),
          key: TABLE_HEADERS.UNBILLED_COUNT,
          width: '75px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.billingCount'),
          key: TABLE_HEADERS.BILLING_COUNT,
          width: '115px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.invoiceDate'),
          key: TABLE_HEADERS.INVOICE_DATE,
          width: '120px',
          filterActive: !!this.invoiceDateFilter,
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.totalPayment'),
          key: TABLE_HEADERS.TOTAL_PAYMENT,
          width: '140px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.payablesBalance'),
          key: TABLE_HEADERS.PAYABLES_BALANCE,
          width: '120px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.processStatus'),
          key: TABLE_HEADERS.PROCESS_STATUS,
          width: '100px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.lastReport'),
          key: TABLE_HEADERS.LAST_REPORT,
          width: '115px',
        },
        {
          header: this.$t('operationManagement.ongoingOperations.table.headers.openTasks'),
          key: TABLE_HEADERS.OPEN_TASKS,
          width: '150px',
        },
        {
          key: 'chat',
          width: '34px',
          customClass: 'p-0',
        },
        {
          key: 'actions',
          width: '40px',
          customClass: 'p-0',
        },
      ];
    },
    tableData() {
      return this.paymentImbalances.map((imbalance) => {
        const {
          billingIds,
          paymentIds,
          orderIds,
          deliveryIds,
          paymentDueDate,
          paymentTerm,
          billedAmounts,
          paidAmounts,
          totalBilledAmount,
          totalPaidAmount,
          paymentStatuses,
          payablesBalance,
          hasInitialBalance,
          overdue,
          supplier,
          billingsLeftToBePaid,
          billingAggregation,
          id,
          closed,
          unbilledOrders,
          periodStart,
          periodEnd,
          businessId,
          balanceAlignment,
          supplierId,
          status,
          statusOverride,
        } = imbalance;
        const missingEvents = filterMissingEventsByDateAndSupplier(
          this.missingEvents,
          this.rehandleParentDocuments,
          supplier.id
        );

        const reconciliationDocs = this.reconciliationDocsBySupplier[supplier.id] ?? [];

        return {
          id,
          reconciliationClosed: closed,
          [TABLE_HEADERS.PAYMENT_DUE_DATE]: paymentDueDate,
          [TABLE_HEADERS.PAYMENT_TERM]: paymentTerm,
          [TABLE_HEADERS.SUPPLIER_NAME]: supplier.name,
          [TABLE_HEADERS.UNBILLED_COUNT]: unbilledOrders.length ? unbilledOrders.length : '-',
          [TABLE_HEADERS.TOTAL_PAYMENT]: billedAmounts.length ? { totalBilledAmount, totalPaidAmount } : null,
          [TABLE_HEADERS.PAYABLES_BALANCE]: payablesBalance,
          [TABLE_HEADERS.LAST_REPORT]: getLastReport(reconciliationDocs),
          reconciliationDocIds: reconciliationDocs
            .sort((a, b) => new Date(b.issueDate) - new Date(a.issueDate))
            .map(({ id }) => id),
          paymentStatus: paymentStatuses[0] ?? null,
          supplier,
          supplierId,
          customer: this.currentTenant,
          billingsLeftToBePaid,
          billedAmounts,
          paidAmounts,
          unbilledOrders,
          billingIds,
          paymentIds,
          orderIds,
          deliveryIds,
          hasInitialBalance,
          overdue,
          missingEvents,
          billingAggregation,
          expandable: true,
          expandableCustomClass: 'p-0',
          totalPaidAmount,
          totalBilledAmount,
          reconciliationPeriod: periodStart,
          periodEnd,
          isDaily: periodStart === periodEnd,
          businessId,
          status,
          statusOverride,
          balanceAlignment,
          tasks: [
            ...this.handleReconciliationTasks.filter(({ data: { reconciliationId } }) => reconciliationId === id),
            ...this.supplierCreationTasks.filter(({ data: { supplierId } }) => supplierId === supplier.id),
            ...this.uncertainBillingTasks.filter(({ data: { supplierId, uncertainBillingId } }) => {
              const uncertainBilling = this.uncertainBillings.find(({ id }) => id === uncertainBillingId);
              const fromDate = DateTime.fromISO(periodStart).minus({ months: 1 }).startOf('month').toISODate();
              const toDate = DateTime.fromISO(periodEnd).plus({ months: 1 }).endOf('month').toISODate();
              return (
                supplierId === supplier.id && fromDate <= uncertainBilling?.date && toDate >= uncertainBilling?.date
              );
            }),
            ...this.balanceAlignmentTasks.filter(({ data: { reconciliationIds } }) => reconciliationIds.includes(id)),
          ],
        };
      });
    },
  },
  methods: {
    paymentDueNetIsNotDefined(paymentTerm) {
      return !paymentTerm || (paymentTerm && !Number.isInteger(paymentTerm.paymentDueNet));
    },
    handleTableRowClick(eventData) {
      this.pickedReconciliationId = eventData.id;
      const rowChatChannelUrl = `reconciliation_${this.currentTenant.id}_${eventData.supplier.id}`;
      if (!this.isChatOpen(rowChatChannelUrl)) {
        this.closeChatModal();
      }
    },
    handleReconciliationTemplateSave(params) {
      !this.reconciliationTemplates.data.some(
        (reco) => reco.supplierId === this.reconciliationTemplateParams.supplierId
      )
        ? this.handleReconciliationParamsCreate(params)
        : this.handleReconciliationParamsUpdate(params);
    },
    async handleReconciliationParamsCreate(params) {
      await this.createReconciliationTemplate(params);
      await this.reconciliationTemplatesRefetch();
      await this.clearReconciliationTemplateParams();
    },
    async handleReconciliationParamsUpdate(params) {
      await this.updateReconciliationTemplate(params);
      await this.reconciliationTemplatesRefetch();
      await this.clearReconciliationTemplateParams();
    },
    async clearReconciliationTemplateParams() {
      this.reconciliationTemplateParams = null;
    },
    supplierTemplateDefined(currSupplierId) {
      return this.reconciliationTemplates.data?.some(({ supplierId }) => supplierId === currSupplierId);
    },
    formatDateShort(date, isDaily) {
      if (!date) return '';
      if (isDaily) return new Date(date).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: '2-digit',
      });
    },
    async handleCloseReconciliation(reconciliationId) {
      try {
        await this.closeReconciliation({ id: reconciliationId });
        this.$message.success(this.$t('payment.paymentTable.messages.reconciliationClosedSuccessfully'));
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('payment.paymentTable.messages.reconciliationClosingFailed'));
      }
    },
    async handleOpenReconciliation(reconciliationId) {
      try {
        await this.updateReconciliation({ id: reconciliationId, patchParams: { closed: false } });
        await this.refetchReconciliations();
        this.$message.success(
          this.$t('operationManagement.ongoingOperations.messages.reconciliationOpenedSuccessfully')
        );
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('operationManagement.ongoingOperations.messages.reconciliationOpeningFailed'));
      }
    },
    formatDate,
    formatDateLong(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: 'numeric',
      });
    },
    async handlePaymentDueNetChange(newPaymentDueNet, eom) {
      this.updatingTerms = true;
      this.paymentTermChangedForceLoading = true;
      if (!this.chosenSupplierPaymentTerm) {
        await this.createTerm({
          termCreateInput: {
            businessId: this.currentTenant.id,
            supplierId: this.supplierPaymentDueModal.id,
            fromDate: new Date(),
            type: 'payment',
            paymentDueNet: newPaymentDueNet,
            eom,
          },
        });
      } else {
        await this.updateTerm({
          termId: this.chosenSupplierPaymentTerm.id,
          termUpdateInput: reject(isNil, {
            businessId: this.currentTenant.id,
            ...omit(['id', 'active', '__typename'], this.chosenSupplierPaymentTerm),
            paymentDueNet: newPaymentDueNet,
            eom,
          }),
        });
      }
      await sleep(8000);
      this.paymentTermChangedForceLoading = false;
      this.termUpdated();
    },
    async handlePaymentMethodChange(newPaymentMethod) {
      this.updatingTerms = true;
      if (!this.chosenSupplierPaymentTerm) {
        await this.createTerm({
          termCreateInput: reject(isNil, {
            businessId: this.currentTenant.id,
            supplierId: this.chosenSupplier.id,
            fromDate: new Date(),
            type: 'payment',
            ...newPaymentMethod,
          }),
        });
      } else {
        await this.updateTerm({
          termId: this.chosenSupplierPaymentTerm.id,
          termUpdateInput: reject(isNil, {
            businessId: this.currentTenant.id,
            ...omit(['id', 'active', '__typename'], this.chosenSupplierPaymentTerm),
            ...newPaymentMethod,
          }),
        });
        this.termUpdated();
      }
    },
    actionsVisibleChange(index, isVisible) {
      this.activeRowActionsIndex = isVisible ? index : -1;
    },
    closeActions() {
      if (this.activeRowActionsIndex !== -1) {
        document.getElementById(`actions-row-${this.activeRowActionsIndex}`).click();
        this.activeRowActionsIndex = -1;
      }
      if (this.activeBalanceDuePopover !== -1) this.activeBalanceDuePopover = -1;
      this.activeDueDatePopover = -1;
    },
    getPastDaysNumber(paymentDueDate) {
      const { days, months, years } = DateTime.local()
        .diff(DateTime.fromISO(paymentDueDate).toUTC(), ['days', 'months', 'years'])
        .toObject();
      if (years) return this.$tc('payment.paymentTable.overduePopover.years', years, { count: Math.round(years) });
      if (months) return this.$tc('payment.paymentTable.overduePopover.months', months, { count: Math.round(months) });
      if (days) return this.$tc('payment.paymentTable.overduePopover.days', days, { count: Math.round(days) });
    },
    getApproachingDaysNumber(paymentDueDate) {
      const { days } = DateTime.local().diff(DateTime.fromISO(paymentDueDate).toUTC(), ['days']).toObject();
      return this.$tc('payment.paymentTable.overduePopover.days', days, { count: Math.round(days) });
    },
    getTotalOverdue(overdue) {
      const total = overdue.reduce((t, { totalUnpaidAmount }) => t + totalUnpaidAmount, 0);
      return this.formatToCurrency(total);
    },
    getPaymentTermTooltipContent(term) {
      const paymentMethod = `payment.exports.paymentMethods.shortName.${term.paymentMethod}`;
      const directDebit = term.directDebit ? ` - ${this.$t('payment.paymentTable.directDebit')}` : '';
      return `${this.$t(paymentMethod)}${directDebit}`;
    },
    handleDueDateClick(rowIndex) {
      this.activeDueDatePopover = this.activeDueDatePopover === rowIndex ? -1 : rowIndex;
      this.activeBalanceDuePopover = -1;
    },
    handleBalanceDueClick(rowIndex) {
      this.activeBalanceDuePopover = this.activeBalanceDuePopover === rowIndex ? -1 : rowIndex;
      this.activeDueDatePopover = -1;
    },
    handleCellClass(rowIndex) {
      if (this.activeReconciliationStatus === rowIndex) return 'bg-secondary';
    },
    openPaymentMethodModal(supplier, paymentTerm) {
      this.chosenSupplier = supplier;
      this.chosenSupplierPaymentTerm = paymentTerm;
      this.isPaymentMethodModalOpen = true;
    },
    openPaymentDueModal(supplier, paymentTerm) {
      this.supplierPaymentDueModal = supplier;
      this.chosenSupplierPaymentTerm = paymentTerm;
    },
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
      this.closeChatModal();
    },
    handleChatOpen({ supplier, reconciliationId, reconciliationPeriod, reconciliationClosed, isDaily }) {
      const formattedPeriod = isDaily
        ? new Date(reconciliationPeriod).toLocaleDateString(this.$i18n.locale, options.twoDigits)
        : DateTime.fromISO(reconciliationPeriod).toLocaleString({
            month: 'short',
            year: 'numeric',
          });
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier,
        title,
        reconciliationId,
        formattedPeriod,
        isChannelEnabled: !reconciliationClosed,
      });
    },
    async validateBalanceAlignment({ reconciliationId, amount, note }) {
      try {
        this.balanceAlignmentReconciliation = null;
        await this.updateReconciliation({
          id: reconciliationId,
          patchParams: { balanceAlignment: reject(isNil, { validated: true, amount, note }) },
        });
        this.$message.success(this.$t('commons.messages.action.success'));
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('commons.messages.action.error'));
      }
    },
    routeToReconciliationStatementReport(supplierId) {
      const route = this.$router.resolve({
        name: 'reconciliationStatementReport',
        params: { supplierId },
      });
      window.open(route.href, '_blank');
    },
    routeToAgedDebtorsReport(supplierId) {
      const route = this.$router.resolve({
        name: 'agedDebtorsReport',
        params: { supplierId },
      });
      window.open(route.href, '_blank');
    },
    routeToEventMapFilteredBySupplier(supplierId, month) {
      const route = this.$router.resolve({
        name: 'supplierActivity',
        params: { supplierId },
        query: { month },
      });
      window.open(route.href);
    },
    async handleAction(command, rowIndex) {
      const { supplier, billedAmounts, unbilledOrders, id, reconciliationPeriod, reconciliationClosed, isDaily } =
        this.tableData[rowIndex];
      switch (command) {
        case ACTIONS.OPEN_CHAT:
          this.handleChatOpen({ supplier, reconciliationId: id, reconciliationPeriod, reconciliationClosed, isDaily });
          break;
        case ACTIONS.BALANCE_UPDATE:
          this.balancePaymentModalSupplier = supplier;
          break;
        case ACTIONS.OPEN_RECONCILIATION_REPORT:
          this.routeToReconciliationStatementReport(supplier.id);
          break;
        case ACTIONS.OPEN_AGED_DEBTORS_REPORT:
          this.routeToAgedDebtorsReport(supplier.id);
          break;
        case ACTIONS.CLOSE_RECONCILIATION:
          {
            try {
              await this.$confirm(
                this.$t('payment.paymentTable.reconciliationCloseConfirm.text', {
                  date: this.formatDateShort(reconciliationPeriod, isDaily),
                  client: supplier.name,
                }),
                this.$t('payment.paymentTable.reconciliationCloseConfirm.title'),
                {
                  showClose: false,
                  confirmButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.confirm'),
                  cancelButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.cancel'),
                  confirmButtonClass: 'el-button--danger',
                  cancelButtonClass: 'el-button--secondary',
                }
              );
              this.handleCloseReconciliation(id);
              // eslint-disable-next-line no-empty
            } catch (err) {}
          }

          break;

        case ACTIONS.OPEN_RECONCILIATION:
          await this.$confirm(
            this.$t('operationManagement.ongoingOperations.reconciliationOpenConfirm.text', {
              date: this.formatDateShort(reconciliationPeriod, isDaily),
              client: supplier.name,
            }),
            this.$t('operationManagement.ongoingOperations.reconciliationOpenConfirm.title'),
            {
              showClose: false,
              confirmButtonText: this.$t('operationManagement.ongoingOperations.reconciliationOpenConfirm.confirm'),
              cancelButtonText: this.$t('operationManagement.ongoingOperations.reconciliationOpenConfirm.cancel'),
              confirmButtonClass: 'el-button--danger',
              cancelButtonClass: 'el-button--secondary',
            }
          );
          this.handleOpenReconciliation(id);
          break;

        case ACTIONS.OPEN_ISSUES_MODAL:
          this.issueModalData = {
            supplierId: supplier.id,
            month: formatBilledDate([...billedAmounts, ...unbilledOrders], true)
              .toISOString()
              .slice(0, 7),
          };
          break;
        case ACTIONS.OPEN_CREDIT_MATCH_MODAL:
          this.creditMatchModalData = {
            supplier,
            month: formatBilledDate([...billedAmounts, ...unbilledOrders], true)
              .toISOString()
              .slice(0, 7),
          };
          break;
        case ACTIONS.OPEN_EVENT_MAP: {
          const month = formatBilledDate([...billedAmounts, ...unbilledOrders], true)
            .toISOString()
            .slice(0, 7);
          this.routeToEventMapFilteredBySupplier(supplier.id, month);
          break;
        }
        case ACTIONS.OPEN_RECONCILIATION_SETTINGS:
          this.reconciliationTemplateParams = {
            supplierId: supplier.id,
            reconciliationTemplate:
              this.reconciliationTemplates.data.find((reco) => reco.supplierId === supplier.id) ??
              this.reconciliationTemplates.default,
          };
          break;
        case ACTIONS.VALIDATE_BALANCE_ALIGNMENT:
          this.balanceAlignmentReconciliation = this.tableData[rowIndex];
          break;
        default:
          break;
      }
    },
    handleInnerTableActions(command, data) {
      const { supplier, billingId, date } = data;
      switch (command) {
        case ACTIONS.OPEN_ISSUES_MODAL:
          this.issueModalData = {
            billingId,
            supplierId: supplier.id,
            month: new Date(date).toISOString().slice(0, 7),
          };
          break;
        case ACTIONS.OPEN_CREDIT_MATCH_MODAL:
          this.creditMatchModalData = {
            billingId,
            supplier,
            month: new Date(date).toISOString().slice(0, 7),
          };
          break;

        default:
          break;
      }
    },
    handleOpenDocument({ documentId, documentsIds }) {
      this.displayedDocument = {
        documentId,
        documentsIds,
        index: documentsIds?.indexOf(documentId),
      };
    },
    handleDocumentModalPagination(page) {
      if (page - 1 === this.displayedDocument.index) return;
      const nextIndex = page - 1;
      this.displayedDocument = {
        ...this.displayedDocument,
        documentId: this.displayedDocument.documentsIds[nextIndex],
        index: nextIndex,
      };
    },
    async handleTaskUpdate(updateData) {
      await this.patchTask({
        taskId: updateData.task.id,
        patchParams: {
          data: { ...updateData.task.data, comment: updateData.comment },
        },
      });
      if (!isNil(updateData.activeAt)) {
        await this.activeTask({
          taskId: updateData.task.id,
          activeParams: {
            activeAt: updateData.activeAt,
          },
        });
      }
      this.tasksRefetch();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.ongoing-activity-table {
  .actions-btn {
    &.active {
      visibility: visible;
    }
  }

  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}

.cell_open-tasks {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.warning-button {
  padding-top: 2px;
}

button.text-warning.warning:not(:hover) {
  color: change-color($color: $warning, $lightness: 38.1%) !important;
}
button.text-danger.danger:not(:hover) {
  color: change-color($color: $error, $lightness: 40%) !important;
}

.add-btn {
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}
.payment-badge-position {
  top: 25%;
  [dir='rtl'] & {
    left: 100%;
    transform: translate(-50%, -50%);
  }
  [dir='ltr'] & {
    left: 0;
    transform: translate(-50%, -50%) scaleX(-1);
  }
}
.document-status-badge {
  top: 15%;
  left: 10%;
}
</style>
